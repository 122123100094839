<template>
  <div class="row" style="padding-top: 20px">
    <div
      class="col-sm-10 col-sm-offset-1"
      style="background: white; border-radius: 10px"
    >

    <google-map ref="map"/>

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>State</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="user in users" :key="user.id" style="text-align: left">
            <td>{{ user.firstName }} {{ user.lastName }}</td>
            <td>{{ user.city || "" }}</td>
            <td>{{ user.state || "" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import { StoreState as state } from "./store/state";
import GoogleMap from './GoogleMap.vue'
export default {
  name: "UsersByLocation",
  components: {GoogleMap},
  data() {
    return {
      center: { lat: 40.689247, lng: -74.044502 },
      markers: {
        position: {
          lat: 40,
          lng: -76
        }
      }
    };
  },
  mounted() {
    this.getUsers();
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getUsers() {
      this.dispatch(StoreActions.getUsers);
    },
  },
  computed: {
    ...mapState(StoreIndex.path, [state.users]),
  },
  watch: {
    users(newVal) {
      console.log(newVal.length)
      newVal.forEach(user => {
        let infoText = `<a target="_blank" href="/#/whiteboard/user/${user.id}">${user.firstName} ${user.lastName}</a>`;
        this.$refs.map.addMarkerWithCoords(user.lat, user.lng, infoText);
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary {
  background: #6c6ea0;
  border-color: #6c6ea0;
}

.btn-primary:hover {
  background: white;
  color: #6c6ea0;
  border-color: #6c6ea0;
}

label {
  color: gray;
}

.itemValue {
  color: black;
  font-size: medium;
}
</style>
