// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getRecent:'getRecent',
    bulkReview: 'bulkReview',
    clearState:'clearState',
    markReviewed: 'markReviewed',
    needsVerify: 'needsVerify',
    reject: 'reject'
};

export const actions = {
    [StoreActions.getRecent](context) {
        RoomieRepository.pendingFlaggedMessageMedia()
        .then((data) => {
            context.commit(StoreMutations.SET_USERS,data.messages)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.markReviewed](context, id) {
        RoomieRepository.reviewFlaggedMessageMedia(id)
            .then(() => {
                var users = [...context.state.users];
                users = users.filter(it => {
                    return it.id != id;
                })
                context.commit(StoreMutations.SET_USERS, users);

                if(users.length === 0) {
                    context.dispatch(StoreActions.getRecent);
                }
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    [StoreActions.bulkReview](context, ids) {
        ids.forEach(id => {
           context.dispatch(StoreActions.markReviewed, id);
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
