// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getPending:'getPending',
    update: 'update',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getPending](context) {
        RoomieRepository.pendingSentiment()
        .then((data) => {
            context.commit(StoreMutations.SET_ITEM,data.item)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.update](context, sentiment) {
        RoomieRepository.updateSentiment(context.state.item.id, sentiment)
        .then(() => {
            context.dispatch(StoreActions.getPending);
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_TIEM, null);
    }
};
