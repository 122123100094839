<template>
  <div class="bg-gray">
    <div class="container-fluid" v-if="listing">
      <div class="user-card">
        <div class="row">
          <div class="col-sm-12">
            <img
              class="user-img"
              v-for="photo in listing.photos"
              :key="photo.id"
              height="500px"
              :src="photo.assetUrl"
            />
          </div>
          <div class="col-sm-12">
            <a :href="`https://admin.ample.software/#/roomie/user/${listing.userId}`"><p>User ID:{{ listing.userId }}</p></a>
          </div>
          <div
            class="col-sm-4"
            v-for="prop in properties"
            :key="prop.value"
          >
            <label>{{ prop.name }}</label>
            <p>{{ listing[prop.value] }}</p>
          </div>
          <div
            class="col-sm-12">
          <button class="btn btn-primary" @click="updateApproved(true)">Approve</button>
          <br><br>
          <button class="btn btn-danger" @click="updateApproved(false)">Deny</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "Listing",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getUser() {
        this.dispatch(StoreActions.get, this.$route.params.id);
    },
    suspendClick() {
      this.suspendConfirm = true
    },
    suspendConfirmClicked() {
      this.suspendConfirm = false
      this.dispatch(StoreActions.suspend);
    },
    updateApproved(approved) {
      this.dispatch(StoreActions.updateHousingApproved, {
        id: this.$route.params.id,
        approved: approved
      })
      this.getUser()
    },
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.listing
    ]),
    properties() {
        return [
            {name:"Name",value:"name"},
            {name:"Desc",value:"desc"},
            {name:"Price",value:"price"},
            {name:"Address",value:"address"},
            {name:"City",value:"city"},
            {name:"State",value:"state"},
            {name:"Zip",value:"zip"},
            {name:"Lat State",value:"lat"},
            {name:"Lng",value:"lng"},
            {name:"Hide Address",value:"hideAddress"},
            {name:"Type",value:"type"},
            {name:"Approved",value:"approved"},
            {name:"Boost",value:"boost"},
        ]

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: #46c3f0;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}
</style>