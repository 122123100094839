// mutation constants

import { StoreState as StoreState } from "./state";

export const StoreMutations = {
  SET_ACTIVE: 'SET_ACTIVE',
  SET_OPENS: 'SET_OPENS',
  SET_SIGNUPS: 'SET_SIGNUPS',
  SET_MONTHLY: 'SET_MONTHLY'
};

export const mutations = {
  [StoreMutations.SET_ACTIVE](state, value) {
    state[StoreState.active] = value
  },
  [StoreMutations.SET_OPENS](state, value) {
    state[StoreState.opens] = value
  },
  [StoreMutations.SET_SIGNUPS](state, value) {
    state[StoreState.signups] = value
  },
  [StoreMutations.SET_MONTHLY](state, value) {
    state[StoreState.monthly] = value
  },
};