export const StoreState = {
    whiteboardStats:'whiteboardStats',
    dailyActiveAverage:'dailyActiveAverage',
    dailySignups:'dailySignups',
    dailyMessages:'dailyMessages',
    dailyOpens:'dailyOpens',
    monthlyActive:'monthlyActive'
}

export const state = {
    [StoreState.whiteboardStats]:null,
    [StoreState.dailyActiveAverage]:null,
    [StoreState.dailySignups]:null,
    [StoreState.dailyMessages]:null,
    [StoreState.dailyOpens]:null,
    [StoreState.monthlyActive]:null
}
