// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getUniversities:'getUniversities',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getUniversities](context) {
        RoomieRepository.popularUniversities()
        .then((data) => {
            context.commit(StoreMutations.SET_ITEMS,data.items)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_ITEMS,[]);
    }
};
