// action constants
import Vue from 'vue'
import WhiteboardRepository from "@/api/WhiteboardRepository";
import { StoreMutations } from './mutations';

export const StoreActions = {
    getMessages:'getMessages',
    clearState:'clearState',
    flagged:'flagged',
    notFlagged:'notFlagged'
};

export const actions = {
    getMessages(context) {
        let mode = context.state.mode
        let page = context.state.page
        let limit = 6;
        WhiteboardRepository.getMessages(mode, page, limit)
        .then((data) => {
            context.commit(StoreMutations.SET_MESSAGES,data.messages)
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    flagged(context,id) {
        WhiteboardRepository.flagged(id)
        .then(() => {
            context.commit(StoreMutations.FILTER_OUT_MESSAGE,id)
            if(context.state.messages.length == 0) {
                context.dispatch(StoreActions.getMessages)
            }
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    notFlagged(context,id) {
        WhiteboardRepository.notFlagged(id)
        .then(() => {
            context.commit(StoreMutations.FILTER_OUT_MESSAGE,id)
            if(context.state.messages.length == 0) {
                context.dispatch(StoreActions.getMessages)
            }
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_MODE,'');
        context.commit(StoreMutations.SET_PAGE,0);
        context.commit(StoreMutations.SET_MESSAGES,[]);
    }
};
