import APIConnection from '@/api/APIConnection';
export default {
    stats(){
        var url = `${APIConnection.v2Url}/whiteboard/stats`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    charts(){
        var url = `${APIConnection.v2Url}/whiteboard/charts`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getMessages(mode,page, limit) {
        var url = `${APIConnection.v2Url}/whiteboard/messages?mode=${mode}&page=${page}&limit=${limit}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    flagged(id) {
        var url = `${APIConnection.v2Url}/whiteboard/messages/flagged`;
        return APIConnection.post(url,{id:id},APIConnection.v2Interceptor);
    },
    notFlagged(id) {
        var url = `${APIConnection.v2Url}/whiteboard/messages/notFlagged`;
        return APIConnection.post(url,{id:id},APIConnection.v2Interceptor);
    },
    getUser(id) {
        var url = `${APIConnection.v2Url}/whiteboard/user/${id}`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    },
    getUsersByLocation() {
        var url = `${APIConnection.v2Url}/whiteboard/users/location`;
        return APIConnection.get(url,APIConnection.v2Interceptor);
    }
}