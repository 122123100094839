// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_MESSAGES:'SET_MESSAGES',
    SET_CONVO_ID: 'SET_CONVO_ID'
};

export const mutations = {
    [StoreMutations.SET_MESSAGES](state,value) {
        state[StoreState.messages] = value
    },
    [StoreMutations.SET_CONVO_ID](state,value) {
        state[StoreState.conversationId] = value
    },
};