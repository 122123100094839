// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_MESSAGES:'SET_MESSAGES',
    SET_PAGE:'SET_PAGE',
    FILTER_OUT_MESSAGE:'FILTER_OUT_MESSAGE',
    SET_MODE:'SET_MODE'
};

export const mutations = {
    [StoreMutations.SET_MESSAGES](state,value) {
        state[StoreState.messages] = value
    },
    [StoreMutations.SET_PAGE](state,value) {
        state[StoreState.page] = value
    },
    [StoreMutations.SET_MODE](state,value) {
        state[StoreState.mode] = value
    },
    [StoreMutations.FILTER_OUT_MESSAGE](state,value) {
        state[StoreState.messages] = state[StoreState.messages].filter(it => {
            return it.id != value
        });
    },
};