<template>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
        <div class="home-card">
          <h3 class="card-title" :style="{'background-color':titleColor}">{{title}}</h3>
          <h3 class="card-value">{{value}}</h3>
        </div>
      </div>
</template>

<script>
export default {
  name: "Card",
  components: {
  },
  props: {
    title: {
      default:''
    },
    value: {
      default:0
    },
    titleColor: {
      default:'lightgray'
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-card {
  background:#fff;
  border-radius: 10px;
}

.card-title {
  padding:10px;
  background:lightgray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color:white;
}

.card-value {
  padding:10px;
  color:black;
  font-weight: 500;
}
</style>
