const defaultAxios = require('axios').default;
import Vue from "vue";

const APIConnection = {
    v2Url:'https://api.ample.software/public',
    get(url,interceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: Vue.prototype.$getSession()
            }
        };

        return instance.get(url, config);
    },
    post(url, params, interceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: Vue.prototype.$getSession()
            }
        };

        return instance.post(url, params, config);
    },
    patch(url, params, interceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: Vue.prototype.$getSession()
            }
        };

        return instance.patch(url, params, config);
    },
    delete(url, params, interceptor) {
        const instance = defaultAxios.create();
        interceptor(instance);

        var config = {
            headers: {
                Authorization: Vue.prototype.$getSession()
            },
            data: params
        };

        return instance.delete(url, config);
    },
    v2Interceptor: (axios) => {
        axios.interceptors.response.use(function (response) {
            let data = response.data;
            if(data.error) {
                return Promise.reject(data.error);
            }
            return data;
        }, function (error) {
            return Promise.reject(error);
        });
    }
};

export default APIConnection;
