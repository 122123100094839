export const StoreState = {
    messages:'messages',
    page: 'page',
    search: 'search'
}

export const state = {
    [StoreState.messages]:[],
    [StoreState.page]: 0,
    [StoreState.search]: ""
}
