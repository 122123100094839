// mutation constants

import {StoreState as StoreState} from "@/store/state";

export const StoreMutations = {
  SET_SESSION:'SET_SESSION',
  SET_USER_ID:'SET_USER_ID',
  SET_CHAT_COUNT:'SET_CHAT_COUNT',
  SET_USER:'SET_USER',
  SET_LOADING:'SET_LOADING'
};

export const mutations = {
  [StoreMutations.SET_SESSION](state,value) {
    state[StoreState.session] = value
  },
  [StoreMutations.SET_USER_ID](state,value) {
    state[StoreState.userId] = value
  },
  [StoreMutations.SET_CHAT_COUNT](state,value) {
    state[StoreState.chatCount] = value
  },
  [StoreMutations.SET_USER](state,value) {
    state[StoreState.user] = value
  },
  [StoreMutations.SET_LOADING](state,value) {
    state[StoreState.loading] = value
  }
};