// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    roomieStats:'roomieStats'
};

export const actions = {
    roomieStats(context, limit) {
        RoomieRepository.charts(limit)
        .then((data) => {
            context.commit(StoreMutations.SET_ACTIVE,data.active);
            context.commit(StoreMutations.SET_OPENS,data.opens);            
            context.commit(StoreMutations.SET_SIGNUPS,data.signups);          
            context.commit(StoreMutations.SET_MONTHLY,data.monthly.map(it => {
                return {
                    date: it.month,
                    count: it.count
                }
            }));                
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    }
};
