// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getSubscriptions:'getSubscriptions',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getSubscriptions](context) {
        RoomieRepository.getSubscriptions()
        .then((data) => {
            context.commit(StoreMutations.SET_SUBSCRIPTIONS, data.subscriptions)
            context.commit(StoreMutations.SET_SUMMARY, data.summary)
            context.commit(StoreMutations.SET_SUM_TOTAL_PRICE, data.sumTotalPrice)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
