// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_ITEMS:'SET_ITEMS'
};

export const mutations = {
    [StoreMutations.SET_ITEMS](state,value) {
        state[StoreState.items] = value
    }
};