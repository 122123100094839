export const StoreState = {
    messages:'messages',
    page:'page',
    mode:'mode'
}

export const state = {
    [StoreState.messages]:[],
    [StoreState.mode]:'',
    [StoreState.page]:0
}
