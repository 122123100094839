// action constants
import Vue from 'vue'
import WhiteboardRepository from "@/api/WhiteboardRepository";
import { StoreMutations } from './mutations';

export const StoreActions = {
    whiteboardStats:'whiteboardStats'
};

export const actions = {
    whiteboardStats(context) {
        WhiteboardRepository.charts()
        .then((data) => {
            context.commit(StoreMutations.SET_DAILY_ACTIVE_AVERAGE,data.dailyActiveAverage);
            context.commit(StoreMutations.SET_DAILY_SIGNUPS,data.dailySignUps);            
            context.commit(StoreMutations.SET_DAILY_MESSAGES,data.dailyMessages);          
            context.commit(StoreMutations.SET_DAILY_OPENS,data.dailyOpens);                
            context.commit(StoreMutations.SET_MONTHLY_ACTIVE,data.monthlyActive);            
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    }
};
