<template>
  <div class="row">
    <GChart
        style="height:400px"
      v-if="data"
      type="LineChart"
      :data="whiteboardChartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "WhiteboardChart",
  props: {
    data: {
      required: false,
    },
    title: {
      default:""
    },
    label: {
      default:""
    }
  },
  computed: {
    chartOptions() {
      return {
          title:this.title,
          colors:['#6C6EA0']
      };
    },
    whiteboardChartData() {
      if (!this.data) {
        return [];
      }
      var keys = Object.keys(this.data);
      var data = [["Day", this.label]];
      keys.forEach((key) => {
        var date = key;
        if(key.length > 7) {
          date = moment(key).format("MMM DD");
        }
        data.push([date, this.data[key]]);
      });

      return data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
