<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-2 col-sm-offset-5">
      <div class="form-group">
        <label>Limit</label>
        <input class="form-control" type="number" v-model="limit" />
      </div>
      <button @click="searchClick" class="btn btn-primary">Search</button>
      <br><br>
    </div>
    <div class="col-sm-10 col-sm-offset-1">
      <roomie-chart v-if="signups" :data="signups" :title="`Daily Signups`" :label="`Signups`" /> <br>

      <roomie-chart v-if="active" :data="active" :title="`Daily Active`" :label="`Active`" /> <br>

      <roomie-chart v-if="opens" :data="opens" :title="`Daily Opens`" :label="`Opens`" /> <br>

      <roomie-chart v-if="monthly" :data="monthly" :title="`Monthly Total`" :label="`Users`" /> <br>
    </div>
  </div>
</template>

<script>
import { StoreActions } from "./store/actions";
import { mapState } from "vuex";
import { StoreState as state } from "./store/state";
import StoreIndex from "./store/_StoreIndex";
import RoomieChart from "@/components/roomie/RoomieChart.vue";
export default {
  name: "Home",
  components: {
    RoomieChart
  },
  data() {
    return {
      limit: 30
    };
  },
  mounted() {
    if (!this.$hasSession()) {
      console.log("has session");
    }
    this.getStats();
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getStats() {
      this.dispatch(StoreActions.roomieStats, this.limit);
    },
    searchClick() {
      this.getStats();
    }
  },
  computed: {
    ...mapState(StoreIndex.path, [
      state.signups,
      state.active,
      state.opens,
      state.monthly

    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  border-radius: 10px;
  height: 50px;
  width: 50px;
}

.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}
</style>
