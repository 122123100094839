// action constants
import Vue from 'vue'
import UserRepository from '@/api/UserRepository';
import { StoreMutations } from './mutations';
export const StoreActions = {
    login:'login'
};

export const actions = {
    login(context,data) {
        UserRepository.login(data)
        .then((data) => {
            let session = data.session;
            Vue.prototype.$setSession(session)
            context.commit(StoreMutations.SET_DID_LOGIN,true)
        })
        .catch((err) => {
            console.log(err)
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    }
};
