// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_USERS:'SET_USERS',
    SET_ID: 'SET_ID'
};

export const mutations = {
    [StoreMutations.SET_USERS](state,value) {
        state[StoreState.users] = value
    },
    [StoreMutations.SET_ID](state,value) {
        state[StoreState.id] = value
    },
};