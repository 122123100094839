<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <p>Page: {{page + 1}} </p>

      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-4 col-sm-offset-4">
          <input class="form-control" :value="search" @input="searchChanged" placeholder="Search" />
          <button @click="searchClick" style="margin-top: 10px;" class="btn btn-primary">Search</button>
        </div>
      </div>


      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-4 col-sm-offset-4">
          <button v-if="page > 0" class="btn btn-default" style="margin-right: 10px;" @click="prev">Prev</button>
          <button class="btn btn-default" @click="next">Next</button>
        </div>
      </div>

      <div class="user-card">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sender</th>
              <th>Sender ID</th>
              <th>Conversation ID</th>
              <th>Message</th>
              <th>TS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in messages" :key="message.id">
              <td class="col-sm-2">
                <router-link :to="`/roomie/user/${message.senderId}`">
                  {{message.senderName}}
                  <br>
                </router-link>
              </td>
              <td>
                <span style="float: left;">{{ message.senderId }}</span>
              </td>
              <td>
                 <router-link :to="`/roomie/conversations/${message.conversationId}/messages`">
                  <span style="float: left;">{{ message.conversationId }}</span>
                 </router-link>
              </td>
              <td>
                <img
                  class="user-img"
                  v-if="message.signedUrl"
                  height="200px"
                  :src="message.signedUrl"
                />
                <span v-else>
                {{ messageString(message.message) }}
                </span>
              </td>
              <td>
                  <span style="float: left;">{{ message.timestamp}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import { StoreMutations } from './store/mutations'

export default {
  name: "Messages",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    searchClick() {
      this.commit(StoreMutations.SET_PAGE, 0);
      this.load()
    },
    load() {
      this.dispatch(StoreActions.getMessages);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    next() {
      this.commit(StoreMutations.SET_PAGE, this.page + 1);
      this.dispatch(StoreActions.getMessages);
    },
    prev() {
      if(this.page == 0) {
        return
      }

      this.commit(StoreMutations.SET_PAGE, this.page - 1);
      this.dispatch(StoreActions.getMessages);
    },
    messageString(msg) {
      return String.raw`${msg}`
    },
    searchChanged(event) {
      const val = event.target.value;
      this.commit(StoreMutations.SET_SEARCH, val);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.messages,
      state.page,
      state.search
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>