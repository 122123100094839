// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
  SET_DID_LOGIN:'SET_DID_LOGIN'
};

export const mutations = {
  [StoreMutations.SET_DID_LOGIN](state,value) {
    state[StoreState.didLogin] = value
  }
};