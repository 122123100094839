// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_LISTINGS:'SET_LISTINGS',
    SET_LISTING: 'SET_LISTING'
};

export const mutations = {
    [StoreMutations.SET_LISTINGS](state,value) {
        state[StoreState.listings] = value
    },
    [StoreMutations.SET_LISTING](state,value) {
        state[StoreState.listing] = value
    }
};