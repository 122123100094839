<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1" style="background:white;border-radius:10px" v-if="user">
      <img :src="user.assetUrl" height="300px" style="border-radius:10px;margin-top:10px"/>
      <h3>{{user.firstName}} {{user.lastName}}</h3>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4"
        style="margin-bottom:20px"
        v-for="item in items"
        :key="item.label">
          <label>{{item.label}}</label>
          <p class="itemValue">{{item.value}}</p>
        </div>
      </div>


      <h4>Sent Messages</h4>
      <card 
        v-for="message in messages"
        :key="message.id"
        :message="message"
        class="img-card"
      />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import Card from '@/components/whiteboard/messages/Card.vue'
// import { StoreMutations } from './store/mutations';
export default {
  name: "User",
  components: {
    Card
  },
  data() {
    return {};
  },
  mounted() {
      this.getUser()
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getUser() {
      let userId = this.$route.params.id
      this.dispatch(StoreActions.getUser,userId);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.user,
      state.messages
    ]),
    items() {
      if(!this.user) {
        return []
      }
      return [
        {
          label:'Username',
          value:`@${this.user.username}`
        },
        {
          label:'Apple ID',
          value:this.user.appleId
        },
        {
          label:'Email',
          value:this.user.email
        },
        {
          label:'Birthday',
          value:this.user.birthday
        },
        {
          label:'Instagram',
          value:this.user.instagram
        },
        {
          label:'Last Open',
          value:this.user.lastOpen
        },
        {
          label:'Widget On',
          value:this.user.widgetOn
        },
        {
          label:'Lat',
          value:this.user.lat
        },
        {
          label:'Lng',
          value:this.user.lng
        },
        {
          label:'Phone Number',
          value:this.user.phoneNumber
        },
        {
          label:'Pro',
          value:this.user.pro
        },
        {
          label:'Ad Free',
          value:this.user.adFree
        },
        {
          label:'Public',
          value:this.user.publicAccount
        },

      ]
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary {
  background:#6C6EA0;
  border-color:#6C6EA0;
}

.btn-primary:hover {
  background:white;
  color:#6C6EA0;
  border-color:#6C6EA0;
}

label {
  color:gray;
}

.itemValue {
  color:black;
  font-size: medium;
}
</style>
