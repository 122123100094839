<template>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
        <div class="home-card">
          <img class="card-img" :src="message.assetUrl" width="100%" height="100%" />
          <p>Sender: <router-link :to="`/whiteboard/user/${message.senderId}`">{{message.senderId}}</router-link></p>
          <p>Receiver:  <router-link :to="`/whiteboard/user/${message.receiverId}`">{{message.receiverId}}</router-link></p>
          <div style="margin-bottom:10px;">
            <button @click="notFlagged" class="btn btn-primary" style="margin-right:5px">Not Flagged</button>
            <button @click="flagged" class="btn btn-danger">Flagged</button>
          </div>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
import _StoreIndex from './store/_StoreIndex';
import { StoreState as state } from './store/state'
export default {
  name: "Card",
  components: {
  },
  props: {
    message:{
      default:{}
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    notFlagged() {
      this.$emit("notFlagged",this.message.id)
    },
    flagged() {
      this.$emit("flagged",this.message.id)
    }
  },
  computed: {
    ...mapState(_StoreIndex.path,[
      state.mode
    ])
  },
  watch: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-card {
  background:#efefef;
  border-radius: 10px;
}

.card-img {
  border-radius: 10px;
}

a {
  color:#6C6EA0;
}
</style>
