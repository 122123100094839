/*
  This file will import any file named _StoreIndex.js that is located in a folder named store.
  This allows us to automatically import StoreIndex files without having to explicitly define them
*/


const requireModule = require.context("@/", true, /store\/_StoreIndex.js$/); //extract js files inside modules folder
const modules = {};

requireModule.keys().forEach(fileName => {
  let parts = fileName.split('/');
  if (parts.length < 2) {
    return;
  }
  let file = requireModule(fileName).default;
  modules[file.path] = file;
});

export default modules;