<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1">
      <whiteboard-chart v-if="dailySignups" :data="dailySignups" :title="`Daily Signups`" :label="`Signups`" />
      <br>
      <whiteboard-chart v-if="dailyActiveAverage" :data="dailyActiveAverage" :title="'Daily Active'" :label="'Active Users'"/>
      <br>
      <whiteboard-chart v-if="dailyMessages" :data="dailyMessages" :title="'Daily Messages'" :label="'Messages'"/>
      <br>
      <whiteboard-chart v-if="dailyOpens" :data="dailyOpens" :title="'Daily Opens'" :label="'Opens'"/>
      <br>
      <whiteboard-chart v-if="monthlyActive" :data="monthlyActive" :title="'Monthly Active'" :label="'Active'"/>
    </div>
  </div>
</template>

<script>
import { StoreActions } from "./store/actions";
import { mapState } from "vuex";
import { StoreState as state } from "./store/state";
import StoreIndex from "./store/_StoreIndex";
import WhiteboardChart from "@/components/whiteboard/WhiteboardChart.vue";
export default {
  name: "Home",
  components: {
    WhiteboardChart
  },
  data() {
    return {
      loginStore: StoreIndex.path,
    };
  },
  mounted() {
    if (!this.$hasSession()) {
      console.log("has session");
      // this.$router.push('/login')
    }
    this.getStats();
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${this.loginStore}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${this.loginStore}/${path}`, value);
    },
    getStats() {
      this.dispatch(StoreActions.whiteboardStats);
    },
  },
  computed: {
    ...mapState(StoreIndex.path, [
      state.dailyActiveAverage,
      state.dailySignups,
      state.dailyMessages,
      state.dailyOpens,
      state.monthlyActive
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
  border-radius: 10px;
  height: 50px;
  width: 50px;
}
</style>
