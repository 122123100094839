<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Type</th>
              <th>Device</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td class="col-sm-2">
                <router-link :to="`/roomie/user/${user.id}`">
                  <img class="user-photo" :src="photo(user)"/>
                  <br>
                </router-link>
                <span style="color:red;" v-if="user.suspended">
                    <br>
                    Suspended
                  </span>
              </td>
              <td>
                <span style="float: left;">{{ user.first_name}} {{user.last_name }}</span>
              </td>
              <td>
                <span style="float: left;">{{ user.type }}
                  <span v-if="user.cityState">
                    <br>
                    {{ user.cityState }}
                  </span>
                </span>
              </td>
              <td>
                  <span style="float: left;">{{ user.deviceId ? 'iOS' : 'Web'}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "ReportedUsers",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getRecent);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    photo(user) {
      if(user.photos.length > 0) {
        return user.photos[0].url;
      }

      return null;
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.users
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>