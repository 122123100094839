export const StoreState = {
  session:'session',
  userId:'userId',
  user:'user',
  chatCount:'chatCount',
  loading:'loading'
}

export const state = {
  [StoreState.session]:null,
  [StoreState.userId]:null,
  [StoreState.user]:null,
  [StoreState.chatCount]:0,
  [StoreState.loading]:false
}
