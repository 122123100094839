export const StoreState = {
    subscriptions:'subscriptions',
    summary: 'summary',
    sumTotalPrice: 'sumTotalPrice'
}

export const state = {
    [StoreState.subscriptions]:[],
    [StoreState.summary]:[],
    [StoreState.sumTotalPrice]: ''
}
