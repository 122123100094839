export const StoreState = {
    user:'user',
    id: 'id',
    users: 'users'
}

export const state = {
    [StoreState.user]:null,
    [StoreState.users]: [],
    [StoreState.id]: null
}
