// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_MESSAGES:'SET_MESSAGES',
    SET_USERS:'SET_USERS'
};

export const mutations = {
    [StoreMutations.SET_USERS](state,value) {
        state[StoreState.users] = value
    }
};