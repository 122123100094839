// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';
import { StoreState } from './state';

export const StoreActions = {
    search: 'search',
    getUser:'getUser',
    clearState:'clearState',
    suspend: 'suspend',
    needsVerify: 'needsVerify',
    grantPro: 'grantPro'
};

export const actions = {
    [StoreActions.search](context, data) {
        RoomieRepository.searchUsers(data)
            .then((data) => {
                context.commit(StoreMutations.SET_USERS,data.users)
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    [StoreActions.getUser](context) {
        RoomieRepository.getUser(context.state[StoreState.id])
        .then((data) => {
            context.commit(StoreMutations.SET_USER,data.user)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.suspend](context) {
        RoomieRepository.suspend(context.state[StoreState.id])
        .then(() => {
            context.dispatch(StoreActions.getUser)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.needsVerify](context) {
        RoomieRepository.needsVerify(context.state[StoreState.id])
        .then(() => {
            context.dispatch(StoreActions.getUser)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.timeout](context) {
        RoomieRepository.timeout(context.state[StoreState.id])
        .then(() => {
            context.dispatch(StoreActions.getUser)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.grantPro](context) {
        RoomieRepository.grantPro(context.state[StoreState.id])
            .then(() => {
                context.dispatch(StoreActions.getUser)
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USER,null);
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
