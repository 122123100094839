// action constants
import Vue from 'vue'
import WhiteboardRepository from "@/api/WhiteboardRepository";
import { StoreMutations } from './mutations';

export const StoreActions = {
    getUsers:'getUsers',
    clearState:'clearState'
};

export const actions = {
    getUsers(context) {
        WhiteboardRepository.getUsersByLocation()
        .then((data) => {
            context.commit(StoreMutations.SET_USERS,data.users)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
