import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import Vuex from 'vuex'
import store from "@/store/store";
import RouterConfig from "@/router/RouterConfig"
import GlobalStoreHelper from '@/store/GlobalStoreHelper'
// import '@/assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as uiv from 'uiv'
import VueCookies from 'vue-cookies'
import VueGoogleCharts from 'vue-google-charts'
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCcNJ5tziAqVyH7q-8VnEh1m6CE_ZPG_fM',
    libraries: "places"
  },
  installComponents: true
});
Vue.component('GmapCluster', GmapCluster);
 
Vue.use(VueGoogleCharts)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(uiv)
Vue.use(GlobalStoreHelper)
Vue.use(VueCookies)
Vue.config.productionTip = false


var router = RouterConfig.router

new Vue({
  render: h => h(App),
  store:store,
  router:router
}).$mount('#app')
