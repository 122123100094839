// mutation constants

import { StoreState as StoreState } from "./state";

export const StoreMutations = {
  SET_WHITEBOARD_STATS: 'SET_WHITEBOARD_STATS',
  SET_DAILY_ACTIVE_AVERAGE: 'SET_DAILY_ACTIVE_AVERAGE',
  SET_DAILY_SIGNUPS: 'SET_DAILY_SIGNUPS',
  SET_DAILY_MESSAGES: 'SET_DAILY_MESSAGES',
  SET_DAILY_OPENS: 'SET_DAILY_OPENS',
  SET_ROOMIE_STATS: 'SET_ROOMIE_STATS'
};

export const mutations = {
  [StoreMutations.SET_WHITEBOARD_STATS](state, value) {
    state[StoreState.whiteboardStats] = value
  },

  [StoreMutations.SET_ROOMIE_STATS](state, value) {
    state[StoreState.roomieStats] = value
  },

  [StoreMutations.SET_DAILY_ACTIVE_AVERAGE](state, value) {
    state[StoreState.dailyActiveAverage] = value
  },

  [StoreMutations.SET_DAILY_SIGNUPS](state, value) {
    state[StoreState.dailySignups] = value
  },

  [StoreMutations.SET_DAILY_MESSAGES](state, value) {
    state[StoreState.dailyMessages] = value
  },
  [StoreMutations.SET_DAILY_OPENS](state, value) {
    state[StoreState.dailyOpens] = value
  }
};