<template>
  <div class="row" style="padding-top: 20px">
    <div class="col-sm-10 col-sm-offset-1" style="background:white;border-radius:10px">
      <div class="row">
        <br>

        <div class="form-group" style="width:200px;text-align:center;margin:0 auto;margin-bottom:10px">
          <label>Mode</label>
          <select class="form-control" @change="modeChanged">
            <option value="">All</option>
            <option value="flagged">Flagged</option>
          </select>
          <br><br>
          <button @click="filter" class="btn btn-primary">Filter</button>
        </div>
        <hr>
        <button @click="prevPage" class="btn btn-primary" style="margin-right:20px">Prev</button>
        <button @click="nextPage" class="btn btn-primary">Next</button>
        <br><br>
        <div class="col-sm-2 col-sm-offset-5" style="margin-bottom: 10px;">
          <input class="form-control" v-model="pageInput" @keyup.enter="search" />
        </div>
      </div>

      <card 
      v-for="message in messages"
      :key="message.id"
      :message="message"
      class="img-card"
      @flagged="markFlagged"
      @notFlagged="notFlagged"
      />
      <div class="row">
        <button @click="prevPage" class="btn btn-primary" style="margin-right:20px">Prev</button>
        <button @click="nextPage" class="btn btn-primary">Next</button>
        <br><br>
        <p>Page: {{page + 1}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import Card from './Card.vue'
import { StoreMutations } from './store/mutations';
export default {
  name: "Messages",
  components: {
    Card
  },
  data() {
    return {
      pageInput: "1"
    };
  },
  mounted() {
      this.getMessages()
  },
  destroyed() {
    this.dispatch(StoreActions.clearState);
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    filter() {
      this.commit(StoreMutations.SET_PAGE,0);
      this.getMessages()
    },
    search() {
      this.commit(StoreMutations.SET_PAGE, parseInt(this.pageInput))
      this.getMessages()
    },
    nextPage() {
      this.commit(StoreMutations.SET_PAGE,this.page + 1)
      this.getMessages()
    },
    prevPage() {
      if(this.page > 0) {
        this.commit(StoreMutations.SET_PAGE,this.page - 1)
      }
      this.getMessages()
    },
    getMessages() {
      this.dispatch(StoreActions.getMessages);
    },
    notFlagged(id) {
      this.dispatch(StoreActions.notFlagged,id)
    },
    markFlagged(id) {
      this.dispatch(StoreActions.flagged,id)
    },
    modeChanged(val) {
      let value = val.target.value;
      this.commit(StoreMutations.SET_MODE,value);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.page,
      state.messages,
      state.mode
    ]),
    adultPending() {
      return this.mode == 'adult_pending'
    }
  },
  watch: {
    page(newVal) {
      this.pageInput = newVal
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary {
  background:#6C6EA0;
  border-color:#6C6EA0;
}

.btn-primary:hover {
  background:white;
  color:#6C6EA0;
  border-color:#6C6EA0;
}
</style>
