<template>
  <div class="row">
    <GChart
        style="height:400px"
      v-if="data"
      type="LineChart"
      :data="roomieChartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
export default {
  name: "RoomieChart",
  props: {
    data: {
      required: false,
    },
    title: {
      default:""
    },
    label: {
      default:""
    }
  },
  computed: {
    chartOptions() {
      return {
          title:this.title,
          colors:['#46c3f0'],
          pointSize:10
      };
    },
    roomieChartData() {
      if (!this.data) {
        return [];
      }
      var data = [["Day", this.label]];
      this.data.forEach(it => {
        data.push([it.date, it.count]);
      });

      return data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
