// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
    SET_SUMMARY: 'SET_SUMMARY',
    SET_SUM_TOTAL_PRICE: 'SET_SUM_TOTAL_PRICE'
};

export const mutations = {
    [StoreMutations.SET_SUBSCRIPTIONS](state,value) {
        state[StoreState.subscriptions] = value
    },
    [StoreMutations.SET_SUMMARY](state,value) {
        state[StoreState.summary] = value
    },
    [StoreMutations.SET_SUM_TOTAL_PRICE](state,value) {
        state[StoreState.sumTotalPrice] = value
    }
};