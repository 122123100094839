import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import modules from "@/store/modules"

import { state } from '@/store/state'
import { mutations } from '@/store/mutations'
import { actions } from '@/store/actions'


export default new Vuex.Store({
  strict: true,
  modules: modules, //automatically imported using the Module.js file
  state: state,
  mutations: mutations,
  actions:actions
});
