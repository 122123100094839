// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    search:'search',
    updateHousingApproved: 'updateHousingApproved',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.search](context, data) {
        RoomieRepository.searchHousing(data)
        .then((data) => {
            context.commit(StoreMutations.SET_LISTINGS,data.items)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.get](context, id) {
        RoomieRepository.getHousing(id)
        .then((data) => {
            context.commit(StoreMutations.SET_LISTING, data.item)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.updateHousingApproved](context, data) {
        RoomieRepository.updateHousingApproved(data)
        .then(() => {
            context.commit(StoreMutations.SET_LISTINGS, context.state.listings.filter(it => {
                return it.id != data.id
            }));
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_LISTINGS,[]);
    }
};
