export const StoreState = {
    active: 'active',
    opens: 'opens',
    signups: 'signups',
    monthly: 'monthly'
}

export const state = {
    [StoreState.active]:null,
    [StoreState.opens]:null,
    [StoreState.signups]:null,
    [StoreState.monthly]:null,
}
