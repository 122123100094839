// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getMessages:'getMessages',
    markReviewed: 'markReviewed',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.markReviewed](context, id) {
        RoomieRepository.reviewFlaggedMessage(id)
            .then(() => {
                var messages = [...context.state.messages];
                messages = messages.filter(it => {
                    return it.id != id;
                })
                context.commit(StoreMutations.SET_MESSAGES, messages);
                if(messages.length === 0) {
                    context.dispatch(StoreActions.getMessages);
                }
            })
            .catch((err) => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
    },
    [StoreActions.getMessages](context) {
        RoomieRepository.pendingFlaggedMessages()
        .then((data) => {
            context.commit(StoreMutations.SET_MESSAGES,data.messages)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
