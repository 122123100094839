// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import RoomieRepository from '../../../../api/RoomieRepository';

export const StoreActions = {
    getRecent:'getRecent',
    clearState:'clearState'
};

export const actions = {
    [StoreActions.getRecent](context) {
        RoomieRepository.getRecentUsers()
        .then((data) => {
            context.commit(StoreMutations.SET_USERS,data.users)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USERS,[]);
    }
};
