// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_MESSAGES:'SET_MESSAGES',
    SET_SEARCH: 'SET_SEARCH'
};

export const mutations = {
    [StoreMutations.SET_MESSAGES](state,value) {
        state[StoreState.messages] = value
    },
    [StoreMutations.SET_SEARCH](state,value) {
        state[StoreState.search] = value
    },
};