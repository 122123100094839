// action constants
import Vue from 'vue'
import WhiteboardRepository from "@/api/WhiteboardRepository";
import { StoreMutations } from './mutations';

export const StoreActions = {
    getUser:'getUser',
    clearState:'clearState'
};

export const actions = {
    getUser(context,id) {
        WhiteboardRepository.getUser(id)
        .then((data) => {
            context.commit(StoreMutations.SET_MESSAGES,data.sentMessages)
            context.commit(StoreMutations.SET_USER,data.user)
        })
        .catch((err) => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_USER,null);
        context.commit(StoreMutations.SET_MESSAGES,[]);
    }
};
