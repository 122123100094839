<template>
  <div class="container-full">
    <div class="row" style="padding-top: 20px">
      <div class="col-sm-6 col-sm-offset-3">
        <div class="row" style="margin-top: 20px;background:white;border-radius:10px">
          <div class="col-xs-12 col-sm-12">
            <div class="pane">
              <h1>Login</h1>
              <div class="form-group">
                <input class="form-control" v-model="username" placeholder="Username" />
              </div>
              <div class="form-group">
                <input class="form-control" v-model="password" type="password" placeholder="Password" />
              </div>
              <div class="form-group">
                <button @click="login" class="btn btn-primary">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StoreActions } from './store/actions';
import StoreIndex from "./store/_StoreIndex";
import { StoreState as state } from "./store/state";
import { StoreMutations as mutations } from "./store/mutations";
import { mapState } from 'vuex';
export default {
  name: "Login",
  components: {
  },
  data() {
    return {
      loginStore: StoreIndex.path,
      username:'',
      password:''
    };
  },
  mounted() {
    if(this.$hasSession()) {
      this.goHome()
    }
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${this.loginStore}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${this.loginStore}/${path}`, value);
    },
    login() {
      if(this.username == '') {
        this.$error("username is required")
        return
      }

      if(this.password == '') {
        this.$error("password is required")
        return
      }

      this.$nextTick(() => {
        this.dispatch(StoreActions.login,{
          username:this.username,
          password:this.password
        })
      });
    },
    goHome () {
      this.$router.push('/home')
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.didLogin
    ])
  },
  watch: {
    didLogin(newVal) {
      if(newVal) {
        this.goHome()
        this.commit(mutations.SET_DID_LOGIN,false)
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
