// import store from "@/store/store";
// import {StoreMutations as GlobalStoreMutations} from '@/store/mutations';
// import {StoreState as GlobalStoreState} from '@//store/state';
const GlobalStoreHelper = {
  install(Vue) {
    Vue.prototype.$setSession = (session) => {
      localStorage.setItem("ample_admin_session",session)
    }
    
    Vue.prototype.$getSession = () => {
      return localStorage.getItem("ample_admin_session")
    }

    Vue.prototype.$hasSession = () => {
      let session = Vue.prototype.$getSession()
      return session && session != "null" && session != "undefined"
    }

    Vue.prototype.$destroySession = () => {
      localStorage.setItem("ample_admin_session",null)
    },

    Vue.prototype.$error = (error) => {
      Vue.prototype.$notify({
        'type':'danger',
        'content':error,
        'placement':'bottom-right'
      })
    },

    Vue.prototype.$success = (error) => {
      Vue.prototype.$notify({
        'type':'success',
        'content':error,
        'placement':'bottom-right'
      })
    },

    Vue.prototype.$getUser = () => {
      Vue.prototype.$store.dispatch("getUser")
    }

    Vue.prototype.$loading = (val) => {
      Vue.prototype.$store.commit("setLoading",val)
    }
  }
};

export default GlobalStoreHelper;