<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <div class="row" style="margin-top: 20px; margin-bottom: 20px">
          <div class="col-xs-12 col-sm-4"></div>
          <div
            class="col-xs-12 col-sm-4"
            style="background: white; border-radius: 10px"
          >
            <br />
            <div class="form-group">
              <label>Approved</label>
              <select class="form-control" v-model="approved">
                <option value="">Pending</option>
                <option value="1">Approved</option>
                <option value="0">Denied</option>
              </select>
            </div>

            <div class="form-group">
              <label>Limit</label>
              <input class="form-control" v-model="limit" />
            </div>

            <div class="form-group">
              <label>Search</label>
              <input class="form-control" v-model="search" />
            </div>

            <button class="btn btn-primary" @click="searchClick">Search</button>
            <br /><br />
          </div>
          <div class="col-xs-12 col-sm-4"></div>
        </div>

        <div class="row" style="margin-bottom: 20px">
          <div
            class="col-sm-12"
            v-for="item in listings"
            :key="item.id"
          >
            <div class="row">
              <div class="col-xs-6 col-sm-3">
                 <router-link :to="`/roomie/housing/${item.id}`">
                <img
                  :src="photo(item)"
                  height="200px"
                  style="border-radius: 10px"
                />
                 </router-link>
                <br /><br />
                <p>
                  Id: <router-link :to="`/roomie/housing/${item.id}`">{{ item.id }}</router-link>
                </p>

                <p>
                  User ID:
                  <router-link :to="`/roomie/user/${item.userId}`">{{ item.userId }}</router-link>
                </p>
                <button @click="approveClick(item)" class="btn btn-primary" style="margin-right:10px;">
                  Approve
                </button>
                <button @click="denyClick(item)" class="btn btn-danger">
                  Deny
                </button>
              </div>
              <div class="col-xs-6 col-sm-9" style="text-align: left">
                <h3>{{ item.name }}</h3>
                <h4>{{ item.price }}</h4>
                <p>
                  {{ item.address }} {{ item.city }} {{ item.state }}
                  {{ item.zip }}
                </p>
                <p>{{ item.desc }}</p>
              </div>
              <div class="col-xs-12 col-sm-12">
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import { StoreState as state } from "./store/state";

export default {
  name: "ReportedUsers",
  components: {},
  data() {
    return {
      approved: "1",
      limit: "10",
      search: ""
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      var approved = this.approved;
      if (approved == "") {
        approved = null;
      }
      this.dispatch(StoreActions.search, {
        approved: approved,
        limit: this.limit,
        search: this.search
      });
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    searchClick() {
      this.load();
    },
    approveClick(item) {
      this.updateApproved(item.id, true);
    },
    denyClick(item) {
      this.updateApproved(item.id, false);
    },
    updateApproved(id, approved) {
      this.dispatch(StoreActions.updateHousingApproved, {
        id: id,
        approved: approved
      })
    },
    photo(item) {
      if(item.photos.length > 0) {
        return item.photos[0].assetUrl;
      }

      return "";
    },
  },
  computed: {
    ...mapState(StoreIndex.path, [state.listings]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>