<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card" v-if="item">
        <br>
        <p>Sender: {{item.senderId}}</p>
        <h4>{{item.message}}</h4>
        <br>
        <button  @click="updateSentiment('Spam')" class="btn btn-danger mr10">Spam</button>
        <button  @click="updateSentiment('Not Spam')" class="btn btn-primary mr10">Not Spam</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "ReportedUsers",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getPending);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    updateSentiment(sentiment) {
      this.dispatch(StoreActions.update, sentiment);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.item
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mr10 {
  margin-right: 10px;
}
.btn {
  font-size: medium;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>