<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>University</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in items" :key="idx">
              <td class="col-sm-6" style="text-align:left;">
                {{item.name}}
              </td>
              <td class="col-sm-6" style="text-align:left;">
                {{item.userCount}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "Locations",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getUniversities);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.items
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>