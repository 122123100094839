<template>
    <modal
        :value="true"
        title="Reject"
        @hide="hideModal"
        @input="hideModal">
        <div>
            <input class="form-control" v-model="text" />
        </div>

        <div style="margin-top: 10px;">
          <button class="btn btn-primary" @click="text = 'Please upload a profile picture of yourself and then resubmit your verification photo.'">Missing Profile Picture</button>
        </div>

        <template #footer>
            <div class="pull-left">
              <btn type="primary" @click="$emit('rejected', text)">Reject</btn>
            </div>
            <div class="pull-right">
              <btn @click="cancelClicked">Cancel</btn>
            </div>
        </template>

    </modal>

</template>

<script type="text/javascript">

    export default {
        name: 'ConfirmationModal',
        props: {

        },
        data() {
          return {
            text: ""
          }
        },
        emits: ['rejected', 'hidden'],
        methods: {
            hideModal() {
                this.$emit('hidden');
            },
            cancelClicked() {
                this.hideModal();
                this.$emit('hidden')
            }
        }
    }

</script>

<style scoped>
</style>
