<template>
  <div>
        <div>
      <h2>Search</h2>
      <label style="width: 30%; text-align:center;">
        <div class="input-group">
          <gmap-autocomplete
        class="form-control"
          @place_changed="setPlace">
        </gmap-autocomplete>
        <span class="btn btn-primary input-group-addon" @click="addMarker">Go</span>
        </div>
      </label>
      <br/>

    </div>
    <br />
    <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 600px">
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
      </gmap-info-window>

      <gmap-cluster>
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="toggleInfoWindow(m,index)"
        ></gmap-marker>
      </gmap-cluster>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      zoom:4,
      center: { lat: 39, lng: -98 },
      markers: [],
      places: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentPlace: null,
      currentMidx: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  mounted() {
    // this.geolocate();
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.center = marker.position
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    setPlace(place) {
      console.log(place)
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        // this.markers.push({ position: marker });
        // this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
        this.zoom = 14
      }
    },
    addMarkerWithCoords(lat, lng, infoText) {
      const marker = {
        lat: lat,
        lng: lng,
      };
      this.markers.push({ position: marker, infoText: infoText });
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
